import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  LandingStep: {
    display: 'flex',
    flexFlow: 'column',
    gap: rem(4),
    marginBottom: rem(24),
    [breakpoints.up('md')]: {
      marginBottom: rem(32),
    },
    [breakpoints.up('lg')]: {
      marginBottom: rem(36),
    },
  },
  title: {
    color: palette.supporting.indigo[200],
  },
  subTitle: {
    color: palette.supporting.indigo[300],
  },
}));
