import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import LandingContainer, {
  LandingContainerProps,
} from '@Landing/Systems/LandingStepper/LandingContainer/LandingContainer';

import useStyles from './styles';

type Compound = {
  Container: LandingContainerProps;
};

const LandingStepper: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> &
  Compound = ({ children, className }) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.LandingStepper, className)}>{children}</div>
  );
};

LandingStepper.Container = LandingContainer;

export default LandingStepper;
