import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'give.courses.v2.advantages.title',
    defaultMessage: 'Les avantages en étant Sherpa',
  },
  subTitle: {
    id: 'give.courses.v2.advantages.subtitle',
    defaultMessage: `Grâce aux cours à domicile et en ligne, être Sherpa te donne la <enter></enter> possibilité d’avoir un job régulier, flexible et bien payé !`,
  },
  subTitleMobile: {
    id: 'give.courses.v2.advantages.mobile.subtitle',
    defaultMessage: `Grâce aux cours à domicile et en ligne,<enter></enter> être Sherpa te donne la possibilité <enter></enter> d’avoir un job régulier, flexible et<enter></enter> bien payé !`,
  },
  cardTitle1: {
    id: 'give.courses.v2.advantages.cardTitle1',
    defaultMessage: 'Cours à domicile, cours en ligne, c’est toi qui vois !',
  },
  cardDescription1: {
    id: 'give.courses.v2.advantages.cardDescription1',
    defaultMessage:
      'Grâce aux cours en ligne, tu es libre de choisir quand donner tes cours.',
  },
  cardTitle2: {
    id: 'give.courses.v2.advantages.cardTitle2',
    defaultMessage: 'Quoi de mieux que d’aider quelqu’un à se dépasser ?',
  },
  cardDescription2: {
    id: 'give.courses.v2.advantages.cardDescription2',
    defaultMessage:
      'Tu vas aussi développer des qualités appréciées pour ton CV !',
  },
  cardTitle3: {
    id: 'give.courses.v2.advantages.cardTitle3',
    defaultMessage: 'Gagne de l’argent grâce à tes connaissances !',
  },
  cardDescription3: {
    id: 'give.courses.v2.advantages.card.description3',
    defaultMessage: `{isMobile, select,
    true {Un petit boulot bien payé qui te permet de financer tes sorties, loisirs, et vacances.}
    other{Fixe tes tarifs pour financer tes sorties, loisirs, et vacances.}
  }`,
  },
  button: {
    id: 'give.courses.form.button',
    defaultMessage: 'Je deviens Sherpa !',
  },
});
