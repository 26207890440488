import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'give.courses.v2.stepper.title',
    defaultMessage:
      'Comment devenir <green>prof particulier</green><enter></enter> chez Les Sherpas ?',
  },
  subTitle: {
    id: 'give.courses.v2.stepper.subTitle',
    defaultMessage: 'L’inscription à notre plateforme est simple est rapide !',
  },
  imgDescription: {
    id: 'give.courses.v2.stepper.description',
    defaultMessage:
      'Inscris-toi dès maintenant.<enter></enter>C’est facile et rapide !',
  },
  titleStep1: {
    id: 'give.courses.v2.stepper.step1.title',
    defaultMessage: 'Inscris-toi gratuitement',
  },
  titleStep2: {
    id: 'give.courses.v2.stepper.step2.title',
    defaultMessage: 'Complète ton annonce',
  },
  titleStep3: {
    id: 'give.courses.v2.stepper.step3.title',
    defaultMessage: 'Donne cours à ton premier élève',
  },
  subtitleStep1: {
    id: 'give.courses.v2.stepper.step.subtitle1',
    defaultMessage:
      'Nom, prénom, email : les basiques suffisent pour créer un compte gratuit et qui le restera.',
  },
  subtitleStep2: {
    id: 'give.courses.v2.stepper.step.subtitle2',
    defaultMessage:
      'Choisis tes matières et niveaux, fixe tes prix, le lieu de tes cours, parle de toi, puis publie !',
  },
  subtitleStep3: {
    id: 'give.courses.v2.stepper.step.subtitle3',
    defaultMessage:
      'Des élèves ne vont pas tarder à vouloir te rencontrer. À toi de décider si tu veux faire cours à domicile et/ou en ligne !',
  },
  button: {
    id: 'give.courses.v2.stepper.step.button',
    defaultMessage: `Je m'inscris !`,
  },
});

export const SEOCourses = defineMessages({
  title: {
    id: 'give.courses.v2.stepper.title',
    defaultMessage:
      'Comment devenir <green>prof particulier</green><enter></enter> chez Les Sherpas ?',
  },
  subTitle: {
    id: 'give.courses.v2.stepper.subTitle',
    defaultMessage: 'Prendre des cours particuliers, c’est très simple !',
  },
  imgDescription: {
    id: 'give.courses.v2.stepper.description',
    defaultMessage: 'Réservez votre premier cours avec l’un de nos Sherpas ! ',
  },
  titleStep1: {
    id: 'give.courses.v2.stepper.step1.title',
    defaultMessage: 'Trouvez un professeur ',
  },
  titleStep2: {
    id: 'give.courses.v2.stepper.step2.title',
    defaultMessage: 'Prenez un cours d’essai offert',
  },
  titleStep3: {
    id: 'give.courses.v2.stepper.step3.title',
    defaultMessage: 'Progressez à votre rythme',
  },
  subtitleStep1: {
    id: 'give.courses.v2.stepper.step.subtitle1',
    defaultMessage:
      'Nous avons passé au peigne fin des centaines de profils de professeurs. Vous allez trouver votre bonheur !',
  },
  subtitleStep2: {
    id: 'give.courses.v2.stepper.step.subtitle2',
    defaultMessage:
      'Une fois que vous avez trouvé un Sherpa, votre premier cours est offert pour voir s’il vous convient.',
  },
  subtitleStep3: {
    id: 'give.courses.v2.stepper.step.subtitle3',
    defaultMessage:
      'Prenez des cours à domicile ou en ligne, où vous voulez, quand vous voulez ! Vous payez à la séance et sans engagement.',
  },
  button: {
    id: 'give.courses.v2.stepper.step.button',
    defaultMessage: `Je m'inscris !`,
  },
});
