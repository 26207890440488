import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  LandingStepper: {
    display: 'flex',
    flexFlow: 'column',
    gap: rem(6),
  },
}));
