import { FC, PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';

import useStyles from './styles';

export type LandingStepProps = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
};

const LandingStep: FC<PropsWithChildren<LandingStepProps>> = ({
  title,
  subtitle,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.LandingStep}>
      <Typography variant="title2Xs" component="h4" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="bodyLg" component="p" className={classes.subTitle}>
        {subtitle}
      </Typography>
    </div>
  );
};

export default LandingStep;
