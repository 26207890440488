import { FC, PropsWithChildren } from 'react';

import LandingPath, {
  LandingPathProps,
} from '@Landing/Systems/LandingStepper/LandingPath/LandingPath';
import LandingStep, {
  LandingStepProps,
} from '@Landing/Systems/LandingStepper/LandingStep/LandingStep';

import useStyles from './styles';

type Compound = {
  Step: FC<PropsWithChildren<LandingStepProps>>;
  Path: FC<PropsWithChildren<LandingPathProps>>;
};
export type LandingContainerProps = FC<PropsWithChildren<unknown>> & Compound;
const LandingContainer: LandingContainerProps = ({ children }) => {
  const { classes } = useStyles();
  return <div className={classes.LandingContainer}>{children}</div>;
};

LandingContainer.Step = LandingStep;
LandingContainer.Path = LandingPath;

export default LandingContainer;
