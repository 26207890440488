import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landing.teacher.hero.title',
    defaultMessage: `Donne des cours particuliers dans plus de 500 matières !`,
  },
  subtitle: {
    id: 'landing.teacher.hero.subtitle',
    defaultMessage: `Deviens le professeur particulier que tu as toujours rêvé d’avoir durant tes études et donne à des milliers d’étudiants la confiance dont ils ont besoin.`,
  },
});
