import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import AdvantageSherpasMessages from '@/components/GiveCourses/AdvantageSherpas/messages';
import HowToBecomeSherpasMessages, {
  SEOCourses,
} from '@/components/GiveCourses/HowToBecomeSherpas/messages';
import LandingTeacherHeroMessages from '@/components/GiveCourses/LandingHeroTeacher/messages';
import LandingCarrousselMessages from '@/components/Landing/LandingCarroussel/messages';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export const LandingHeroTeacherDefault = {
  defaultTitle: <FormattedMessage {...LandingTeacherHeroMessages.title} />,
  defaultSubtitle: (
    <FormattedMessage {...LandingTeacherHeroMessages.subtitle} />
  ),
  defaultBackgroundUrl:
    'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-hero-teacher.jpg',
};

export const LandingHeroTeacherCourses = {
  defaultTitle: <FormattedMessage {...LandingTeacherHeroMessages.title} />,
  defaultSubtitle: (
    <FormattedMessage {...LandingTeacherHeroMessages.subtitle} />
  ),
  defaultBackgroundUrl:
    'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/courses-seo-hero.jpg',
};

export const AdvantageSherpasDefault = {
  defaultTitle: <SEOFormattedMessage {...AdvantageSherpasMessages.subTitle} />,
  defaultSubtitle: (
    <SEOFormattedMessage {...AdvantageSherpasMessages.subTitleMobile} />
  ),
};

export const LandingTeacherCarrousselDefault = {
  defaultTitle: <SEOFormattedMessage {...LandingCarrousselMessages.title} />,
  defaultSubtitle: (
    <SEOFormattedMessage {...LandingCarrousselMessages.subTitle} />
  ),
};

export const HowToBecomeSherpasDefault = {
  defaultTitle: <SEOFormattedMessage {...HowToBecomeSherpasMessages.title} />,
  defaultSubtitle: (
    <FormattedMessage {...HowToBecomeSherpasMessages.subTitle} />
  ),
  defaultBackgroundUrl:
    'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/prof-GiveCourses-stepper.jpg',
  defaultLabel: (
    <SEOFormattedMessage {...HowToBecomeSherpasMessages.imgDescription} />
  ),
  defaultSections: [
    {
      title: <FormattedMessage {...HowToBecomeSherpasMessages.titleStep1} />,
      subtitle: (
        <FormattedMessage {...HowToBecomeSherpasMessages.subtitleStep1} />
      ),
    },
    {
      title: <FormattedMessage {...HowToBecomeSherpasMessages.titleStep2} />,
      subtitle: (
        <FormattedMessage {...HowToBecomeSherpasMessages.subtitleStep2} />
      ),
    },
    {
      title: <FormattedMessage {...HowToBecomeSherpasMessages.titleStep3} />,
      subtitle: (
        <FormattedMessage {...HowToBecomeSherpasMessages.subtitleStep3} />
      ),
    },
  ],
};

export const HowToBecomeSherpasCourses = {
  defaultTitle: <SEOFormattedMessage {...SEOCourses.title} />,
  defaultSubtitle: <FormattedMessage {...SEOCourses.subTitle} />,
  defaultBackgroundUrl:
    'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/course-seo-stepper.jpg',
  defaultLabel: (
    <Typography variant="titleSm" component="h3">
      <SEOFormattedMessage {...SEOCourses.imgDescription} />
    </Typography>
  ),
  defaultSections: [
    {
      title: <FormattedMessage {...SEOCourses.titleStep1} />,
      subtitle: <FormattedMessage {...SEOCourses.subtitleStep1} />,
    },
    {
      title: <FormattedMessage {...SEOCourses.titleStep2} />,
      subtitle: <FormattedMessage {...SEOCourses.subtitleStep2} />,
    },
    {
      title: <FormattedMessage {...SEOCourses.titleStep3} />,
      subtitle: <FormattedMessage {...SEOCourses.subtitleStep3} />,
    },
  ],
};
