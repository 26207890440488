import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import useStyles from './styles';

export type LandingPathProps = {
  stepNumber: number;
  isWithOutDot?: boolean;
};

const LandingPath: FC<PropsWithChildren<LandingPathProps>> = ({
  stepNumber,
  isWithOutDot = false,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.LandingPath}>
      <div className={classes.circle}>
        <Typography
          variant="title2Xs"
          component="span"
          className={classes.text}
        >
          {stepNumber}
        </Typography>
      </div>
      {!isWithOutDot && <div className={classes.dotPath} />}
    </div>
  );
};

export default LandingPath;
