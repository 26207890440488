import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  LandingPath: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: 'fit-content',
    gap: rem(6),
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.supporting.indigo[500],
    border: `${rem(4)} solid ${palette.supporting.indigo[700]}`,
    width: rem(32),
    height: rem(32),
    borderRadius: rem(1000),
    [breakpoints.up('md')]: {
      width: rem(36),
      height: rem(36),
    },
  },
  text: {
    color: palette.common.white,
  },
  dotPath: {
    height: '100%',
    width: rem(1),
    borderRight: `${rem(8)} dotted ${palette.supporting.indigo[700]}`,
  },
}));
