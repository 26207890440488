import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  LandingContainer: {
    display: 'flex',
    gap: rem(8),
    [breakpoints.up('md')]: {
      gap: rem(16),
    },
  },
}));
