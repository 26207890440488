import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette: { common, text }, breakpoints }) => ({
  howToBecomeSherpas: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: common.white,
    padding: `${rem(40)} ${rem(16)}  ${rem(48)}`,
    gap: rem(36),
    [breakpoints.up('md')]: {
      padding: `${rem(60)} ${rem(40)} ${rem(48)}`,
      gap: rem(40),
    },
    [breakpoints.up('lg')]: {
      padding: rem(80),
      gap: rem(60),
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
  },
  subTitle: {
    textAlign: 'center',
    color: text.secondary,
    marginTop: rem(16),
    [breakpoints.up('md')]: {
      fontSize: rem(20),
      marginTop: rem(20),
    },
  },
  imageContainer: {
    minHeight: rem(159),
    [breakpoints.up('md')]: {
      minHeight: rem(388),
    },
    [breakpoints.up('lg')]: {
      minHeight: rem(480),
    },
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(52.97deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 70.69%)',
    filter: 'drop-shadow(0px 4px 8px rgba(24, 24, 27, 0.04))',
  },
  imageTitle: {
    color: common.white,
    position: 'absolute',
    zIndex: 2,
    bottom: rem(20),
    left: rem(20),
    [breakpoints.up('md')]: {
      bottom: rem(44),
      left: rem(40),
    },
    [breakpoints.up('lg')]: {
      bottom: rem(52),
      left: rem(40),
    },
  },
  stepper: {
    flex: 1,
  },
  feature: {
    [breakpoints.up('md')]: { padding: rem(28) },
  },
}));
